body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  color: #333333;
}
/* custom scrollbar  */
  ::-webkit-scrollbar {
   width: 0.3rem;
   height: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow:inset 0 0 6px rgba(0,0,0,0.00) ;
  }
  

  ::-webkit-scrollbar-thumb {
    background:rgba(0,0,0,0.2);
    border-radius: 8px;
  }
  

  ::-webkit-scrollbar-thumb:hover {
    background:#7c1eff;
  } 
